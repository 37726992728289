import { lazy } from "react";
import { MODAL } from "redux/slices/modal";
import ExportTableOptions from "./ExportTableOptions";
import Views from "./Views";
import IconGallery from "./IconGallery";
import AddCampaign from "./AddCampaign";
import AddList from "./AddList";

const AddPayment = lazy(() => import("./AddPayment"));
const HandleStatus = lazy(() => import("./HandleStatus"));
const ConfirmationForm = lazy(() => import("./ConfirmationForm"));
const ConfirmationModal = lazy(() => import("./ConfirmationModal"));
const ListRequestDetails = lazy(() => import("./ListRequestDetails"));
const AddListRequest = lazy(() => import("./AddListRequest"));
const SendMail = lazy(() => import("./SendMail"));
const SendFollowup = lazy(() => import("./SendFollowup"));
const AssignList = lazy(() => import("./AssignList"));
const AddVideoReview = lazy(() => import("./AddVideoReview"));
const SendMailBrand = lazy(() => import("./SendMailBrand"));

export { default } from "./AppModal";

export type ModalMapper = {
  [key in MODAL]: "" | JSX.Element;
};

export const modalMapper: ModalMapper = {
  ADD_PAYMENT: <AddPayment />,
  HANDLE_STATUS: <HandleStatus />,
  CONFIRMATION_FORM: <ConfirmationForm />,
  CONFIRMATION_MODAL: <ConfirmationModal />,
  EXPORT_TABLE_OPTIONS: <ExportTableOptions />,
  ADD_CAMPAIGN: <AddCampaign />,
  VIEWS: <Views />,
  ICON_GALLERY: <IconGallery />,
  ADD_LIST: <AddList />,
  LIST_REQUEST_DETAILS: <ListRequestDetails />,
  ADD_LIST_REQUEST: <AddListRequest />,
  SEND_MAIL: <SendMail />,
  SEND_FOLLOWUP: <SendFollowup />,
  ASSIGN_LIST: <AssignList />,
  Add_VIDEO_REVIEW: <AddVideoReview />,
  SEND_MAIL_BRAND: <SendMailBrand />,
};
