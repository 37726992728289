import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import authReducer from "./slices/auth";
import userReducer from "./slices/user";

import modalReducer from "./slices/modal";
import loaderReducer from "./slices/loader";
import formLoaderReducer from "./slices/formLoader";
import { mapReducer } from "./slices/map/mapSlice";
import { roleReducer } from "./slices/role/roleSlice";
import timeZoneReducer from "./slices/timeZone/timeZoneSlice";
import campaignReducer from "./slices/campaign";
import listReducer from "./slices/list";
import creatorReducer from "./slices/creator";
import companyReducer from "./slices/company";
import listRequestReducer from "./slices/listRequest";
import gmailReducer from "./slices/gmail";
import brandReducer from "./slices/brand";
import dashboardReducer from "./slices/dashboard";

const appReducer = combineReducers({
  form: formReducer,
  map: mapReducer,
  role: roleReducer,
  auth: authReducer,
  user: userReducer,
  modal: modalReducer,
  loader: loaderReducer,
  formLoader: formLoaderReducer,
  timeZone: timeZoneReducer,
  campaign: campaignReducer,
  list: listReducer,
  creator: creatorReducer,
  company: companyReducer,
  listRequest: listRequestReducer,
  gmail: gmailReducer,
  brand: brandReducer,
  dashboard: dashboardReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/logout") state = {};
  return appReducer(state, action);
};

export default rootReducer;
