export { default, modalActions, modalSlice } from "./modalSlice";

export enum MODAL {
  ADD_PAYMENT = "ADD_PAYMENT",
  HANDLE_STATUS = "HANDLE_STATUS",

  CONFIRMATION_FORM = "CONFIRMATION_FORM",
  CONFIRMATION_MODAL = "CONFIRMATION_MODAL",

  VIEWS = "VIEWS",

  EXPORT_TABLE_OPTIONS = "EXPORT_TABLE_OPTIONS",
  ADD_CAMPAIGN = "ADD_CAMPAIGN",
  ADD_LIST = "ADD_LIST",
  ICON_GALLERY = "ICON_GALLERY",
  LIST_REQUEST_DETAILS = "LIST_REQUEST_DETAILS",
  ADD_LIST_REQUEST = "ADD_LIST_REQUEST",
  SEND_MAIL = "SEND_MAIL",
  SEND_FOLLOWUP = "SEND_FOLLOWUP",
  ASSIGN_LIST = "ASSIGN_LIST",
  Add_VIDEO_REVIEW = "Add_VIDEO_REVIEW",
  SEND_MAIL_BRAND = "SEND_MAIL_BRAND",
}

export type ModalType = "" | keyof typeof MODAL;

export interface OpenModalState {
  data: any;
  width: any;
  type: ModalType;
  loading?: boolean;
}

export type ModalState = OpenModalState & {
  open: boolean;
};
