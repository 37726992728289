import { config } from "config";
import { SetDashboardsPayload, DashboardState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";

const default_page_size = config.PAGE_SIZE;
const initialState: DashboardState = {
  count: {},

  loading: false,
  top: {},
  graph: {},
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCount: (state, action: PayloadAction<any>) => {
      state.count = action.payload;
    },
    setGraph: (state, action: PayloadAction<any>) => {
      state.graph = action.payload;
    },
    setTop: (state, action: PayloadAction<any>) => {
      state.top = action.payload;
    },
  },
});

const dashboardReducer = dashboardSlice.reducer;

export const dashboardActions = dashboardSlice.actions;
export default dashboardReducer;
