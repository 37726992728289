import { config } from "config";
import { SetBrandsPayload, BrandState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";

const default_page_size = config.PAGE_SIZE;
const initialState: BrandState = {
  count: 0,
  brands: [],
  brand: null,
  loading: false,
  refresh: 0,
  current_filters: {},
  refreshLoader: false,
  brandOptions: [],
  options: [],
  filters: { page: 1, pageSize: default_page_size },
};

export const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    clear_search: (state) => {
      state.brands = [];
      state.brandOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setOptions: (state, action: PayloadAction<any[]>) => {
      state.brandOptions = action.payload;
    },
    updateBrand: (state, action) => {
      const { id, brand } = action.payload;
      state.brands.every(({ _id }, i) => {
        if (id === _id) {
          state.brands[i] = brand;
          return false;
        }
        return true;
      });
    },
    deleteBrandById: (state, action) => {
      const id = action.payload;
      state.brands.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.brands.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setBrand: (state, action) => {
      state.brand = action.payload;
    },

    addBrand: (state, action) => {
      state.brands.unshift(action.payload);
    },

    setBrands: (state, action: PayloadAction<SetBrandsPayload>) => {
      const { count, brands } = action.payload;
      state.count = count;
      state.refreshLoader = false;
      state.brands = brands;
    },
    setSearchBrands: (state, action: PayloadAction<SetBrandsPayload>) => {
      const { count, brands } = action.payload;
      state.count = count;
      state.refreshLoader = false;

      let options: SelectOption[] = [];
      brands.forEach(({ _id, fullName, email }: any) =>
        options.push({ value: _id, label: fullName + " " + " - " + email })
      );
      state.brands = brands;
      state.brandOptions = options;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters.page = action.payload;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const brandReducer = brandSlice.reducer;

export const brandActions = brandSlice.actions;
export default brandReducer;
